var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-table",
        {
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.dataSource,
            "table-ref": ".table",
            "row-key": "jobGradeId",
            pagination: false,
            loading: _vm.loading,
            "selection-mode": _vm.selectionMode,
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              columnWidth: 25,
              onChange: _vm.onSelectChange,
            },
          },
          on: { change: _vm.loadDataList },
          scopedSlots: _vm._u([
            {
              key: "switchStatus",
              fn: function (ref) {
                var text = ref.text
                var scope = ref.scope
                return _c("span", {}, [
                  _c(
                    "div",
                    [
                      _c("a-switch", {
                        attrs: {
                          checked:
                            scope.record.switchStatus === 1 ? true : false,
                          size: "small",
                        },
                        on: {
                          change: function ($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.onChange.apply(
                              void 0,
                              argsArray.concat([scope])
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              },
            },
            {
              key: "operation",
              fn: function (ref) {
                var text = ref.text
                var scope = ref.scope
                return [
                  _c(
                    "span",
                    { staticClass: "operation-btn-box" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(scope.record)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "delete",
                          attrs: { type: "link" },
                          on: {
                            "~click": function ($event) {
                              return _vm.deleteRank(scope.record)
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "span",
            { attrs: { slot: "proportionTitle" }, slot: "proportionTitle" },
            [
              _vm._v(" 占比 "),
              _c(
                "a-tooltip",
                { attrs: { placement: "top" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [_vm._v("当前职级人数占当前职类总人数的比例")]),
                  ]),
                  _c(
                    "span",
                    [
                      _c("a-icon", { attrs: { type: "question-circle" } }),
                      _vm._v(" (%) "),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c("Edit", { ref: "edit", on: { complate: _vm.serchGetData } }),
      _c("CreateRank", { ref: "CreateRank" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
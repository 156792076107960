var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        title: "编辑职级信息",
        size: "normal",
        visible: _vm.visible,
        "mask-closable": false,
        "confirm-loading": _vm.confirmLoading,
      },
      on: {
        ok: _vm.handleOk,
        cancel: _vm.handleCancel,
        afterClose: _vm.afterClose,
      },
    },
    [_c("k-form-build", { ref: "KFB", attrs: { value: _vm.jsonData } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }